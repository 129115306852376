<template>
  <a-card
    style="background: transparent"
    :hoverable="true"
  >
    <slot
      name="title"
      :title="file.title"
      :toolTipText="file.toolTipText"
    ></slot>
    <a-upload-dragger
      :disabled="isFileLoading"
      :fileList="documentsList"
      :multiple="multiple"
      :customRequest="(e) => getFile(e)"
      :beforeUpload="(e) => beforeUpload(e)"
      @change="(e) => handleChange(e)"
      :remove="handleRemove"
      class="avatar-uploader"
    >
      <p class="ant-upload-drag-icon">
        <a-icon type="inbox" />
      </p>
      <p class="ant-upload-text">{{ file.uploadText }}</p>
    </a-upload-dragger>
    <a-badge
      :status="isAnyError ? 'error' : 'success'"
      v-show="!isFileLoading && isAnyFile"
      :text="isAnyError ? 'Onjuist' : 'Goedgekeurd'"
    />
    <CustomSpinner
      v-if="isFileLoading || isFileRemoving"
      class="spinner"
      :color="isFileRemoving ? 'red' : null"
    />
    <a-list
      size="small"
      bordered
      v-show="isAnyError"
      style="margin-top: 15px"
    >
      <a-list-item
        v-for="error in errorText"
        :key="error"
      >
        <a-badge
          :status="'error'"
          :text="error"
        />
      </a-list-item>
    </a-list>
  </a-card>
</template>

<script>
import CustomSpinner from "./CustomSpinner.vue";

export default {
  name: 'CustomFilesUploader',
  props: {
    file: {
      type: Object,
      require: true,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    clearErrorsFunc: {
      type: Function,
      require: true,
    },
    addErrorsFunc: {
      type: Function,
      require: true,
    },
    maxUploadFileSize: {
      type: Number,
      default: 10,
    },
    addFileFunc: {
      type: Function,
      require: true,
    },
    removeFileFunc: {
      type: Function,
      require: true,
    },
    clearFileState: {
      type: Function,
      require: true,
    },
  },
  data() {
    return {
      onSuccess: false,
      biggerThanMaxSize: false,
    }
  },
  components: {
    CustomSpinner,
  },
  computed: {
    documentsList() {
      return this.file.file.map(item => item.file);
    },
    isAnyFile() {
      return !!this.file.file.length;
    },
    errorText() {
      return this.file.errorText;
    },
    isAnyError() {
      return !!this.file.errorText.length;
    },
    isFileLoading() {
      return this.file.isFileLoading;
    },
    isFileRemoving() {
      return this.file.isFileRemoving;
    },
  },
  methods: {
    isRepeatedFile(document) {
      return this.file.file.some(({ file: { name } }) => document.name === name);
    },
    getFile({ onSuccess }) {
      this.onSuccess = true;
      setTimeout(() => {
        onSuccess("ok");
      }, 0);
    },
    async beforeUpload(file) {
      this.clearErrorsFunc();
      this.biggerThanMaxSize = false;
      if (this.isRepeatedFile(file)) {
        this.addErrorsFunc({
          err: ["Dit bestand is al gedownload!"],
        });
        return
      };
      const isLt10M = file.size / 1024 / 1024 < this.maxUploadFileSize;
      if (!isLt10M) {
        this.biggerThanMaxSize = true;
        this.addErrorsFunc({
          err: ["Het document moet kleiner zijn dan 10 MB!"],
        });
        return;
      };
      this.biggerThanMaxSize = false;
      return !isLt10M;
    },
    handleChange(info) {
      if (this.isRepeatedFile(info.file) || this.biggerThanMaxSize) return;
      if (this.onSuccess && info.fileList.length) {
        if (info.file.size > 1024 * 1024 * this.maxUploadFileSize) return;
        this.addFileFunc({ file: info.file.originFileObj });
        this.file.isDirty = true;
        this.onSuccess = false;
        this.biggerThanMaxSize = false;
      };
      if (!info.fileList.length) {
        this.file.isDirty = false;
        this.clearFileState();
      };
    },
    handleRemove({ uid }) {
      this.biggerThanMaxSize = false;
      const { id } = this.file.file.find(({ file }) => file.uid === uid)
      if (!id) return;
      this.removeFileFunc(id);
    }
  },
}
</script>

<style lang="scss" scoped>
  .spinner {
    justify-content: flex-start;
  }
</style>
