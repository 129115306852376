<template>
  <div :class="className">
    <a-checkbox
      @change="$emit('checkHandler', $event.target.checked)"
      :checked="checked"
      :disabled="disabled"
    >
      {{ title }}
    </a-checkbox>
  </div>
</template>

<script>
export default {
  name: "CustomCheckbox",
  props: {
    title: {
      type: String,
      required: true,
      default: "Checkbox"
    },
    className: {
      type: String,
    },
    checked: {
      type: Boolean,
      required: true,
    },
    disabled: {
      type: Boolean,
      required: true,
      default: false
    },
  },
};
</script>