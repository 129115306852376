<template>
  <div>
    <a-card
      class="card card--products"
      :hoverable="true"
    >
      <a-list
        v-for="(product, index) of filteredProducts"
        :key="product.id"
        class="list"
        size="large"
        :bordered="true"
      >
        <a-list-item
          v-for="(option, key, index) of product.options"
          :key="product.id + option.value + index"
        >
          <div class="list-item">
            <span>
              <b>
                {{ option.dictionary }}
              </b>
            </span>
            <span class="list-item__value">
              {{ option.value || '---' }}
            </span>
          </div>
        </a-list-item>
        <a-list-item>
          <div class="list-item">
            <span>
              <b>
                Aantal te versturen dozen
              </b>
            </span>
            <span class="list-item__value">
              {{ product.countOfBoxes || '---' }}
            </span>
          </div>
        </a-list-item>

        <a-list-item>
          <div class="list-item">
            <span>
              <b>
                Hoeveelheid dozen producttype
              </b>
            </span>
            <span class="list-item__value">
              {{ countOfSubProductTypes(product) }}
            </span>
          </div>
        </a-list-item>
        <a-list-item>
          <div class="list-item">
            <span>
              <b>
                Persoonlijke referentie
              </b>
            </span>
            <span class="list-item__value">
              {{ product.personalReference || '---' }}
            </span>
          </div>
        </a-list-item>
        <div slot="header">
          <h2 style="margin: 0">
            Product №{{ index + 1 }}
          </h2>
        </div>
      </a-list>
    </a-card>
    <a-card
      class="card card--fields"
      :hoverable="true"
    >
      <a-list
        class="list"
        :bordered="true"
        size="large"
      >
        <a-list-item
          v-for="(field, key) of formFields"
          :key="key"
        >
          <div class="list-item">
            <span>
              <b>
                {{ field.checkoutDictionary || field.dictionary || '---' }}
              </b>
            </span>
            <span class="list-item__value">
              {{ field.name | modifyFieldValue(key)}}
            </span>
          </div>
        </a-list-item>
        <div slot="header">
          <h2 class="list__header">
            LEVERING
          </h2>
        </div>
      </a-list>
    </a-card>
    <a-card
      class="card card--fields"
      :hoverable="true"
    >
      <CustomInput
        v-for="(value, name) of checkoutFormFields"
        :formFields="checkoutFormFields"
        :fieldData="value"
        :fieldName="name"
        :validation="validations[name]"
        :key="name + value.id"
      />
    </a-card>
  </div>
</template>

<script>
import CustomInput from "./CustomInput";
import { mapGetters } from "vuex";

export default {
  name: "WarehouseFormCheckout",
  components: {
    CustomInput
  },
  props: {
    validations: Object
  },
  filters: {
    modifyFieldValue: function (val, fieldName) {
      if (fieldName === 'lvb' || fieldName === 'dhl') return val ? 'Ja' : 'Nee'
      if (fieldName === 'country') return val === 1 ? 'Nederland' : 'België'
      if (fieldName === 'lvbOptions') {
        if (!val) return
        let parsedObj = JSON.parse(val)
        return `
          Stad: ${ parsedObj.city };
          Land: ${ parsedObj.country };
          Postcode: ${ parsedObj.postcode };
          Straat: ${ parsedObj.street };
          Huisnummer: ${ parsedObj.house_number }
        `
      }
      return val || '---'
    }
  },
  beforeDestroy () {
    this.$store.commit('warehouse/CLEAR_SHIPPING_ERRORS')
  },
  computed: {
    ...mapGetters('warehouse', ['products', 'formFields', 'checkoutFormFields']),
    filteredProducts () {
      return this.products.filter(product => product.available && product.checked)
    },
  },
  methods: {
    countOfSubProductTypes (product) {
      return product.product_subs.filter(({ checked, countOfSubProductBoxes }) => checked && countOfSubProductBoxes).length;
    },
  }
}
</script>

<style scoped lang="scss">
  .card {
    border-radius: 5px;
    &--products {
      overflow-y: auto;
      max-height: 800px;
    }
    &--fields {
      margin-top: 15px;
    }
  }
  .list {
    margin-bottom: 15px;
    &__header {
      margin: 0;
    }
  }
  .list-item {
    display: flex;
    justify-content: space-between;
    width: 100%;
    &__value {
      font-size: 16px; max-width: 700px
    }
  }
</style>
