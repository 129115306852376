<template>
  <div class="wrapper">
    <p class="label" :class="{ 'label--hasError': validation.$error }">{{ label }}</p>
    <a-date-picker
      size="large"
      :class="{ hasError: validation.$error }"
      :locale="locale"
      @change="$emit('change', $event)"
      @openChange="change"
      :value="value"
      :defaultValue="null"
      :disabled-date="disabledDate"
      format="DD-MM-YYYY"
    >
      <a-icon slot="suffixIcon" type="calendar" :style="{ color: validation.$error ? '#f5222d' : null }" />
    </a-date-picker>
  </div>
</template>

<script>
import locale from "ant-design-vue/es/date-picker/locale/nl_NL";
import moment from 'moment';
import 'moment/locale/nl';
import { DatePicker } from "ant-design-vue";

export default {
  name: "CustomDateInput",
  components: {
    ADatePicker: DatePicker,
  },
  props: {
    label: String,
    value: String,
    validation: Object
  },
  data() {
    return {
      weekends: [6, 7],
      weekdays: [1, 2, 3, 4, 5],
      locale
    }
  },
  methods: {
    moment,
    change (val) {
      if (!val) {
        this.validation.$touch()
      }
    },
    disabledDate(current) {
      const currentDayNum = current.isoWeekday()
      const weekdayNum = moment().isoWeekday()
      // disable date before today
      if (current < moment().endOf('day')) return true;

    // для после обеда 2022-04-14
      if (moment().dayOfYear() === 104) {
        if(moment().startOf('day').fromNow().slice(0, 2) >= 11.5) {
          return current.isBetween('2022-04-14', '2022-04-20')
        }
      }
      // для всего дня 2022-04-15 (пятница)
      if (moment().dayOfYear() === 105) {
        return current.isBetween('2022-04-14', '2022-04-20')
      }

      // для всего дня 2022-04-18 (понедельник)
      if (moment().dayOfYear() === 108) {
        return current.isBetween('2022-04-18', '2022-04-20')
      }
      // disable weekends
      if (this.weekends.includes(currentDayNum)) return true;
      // disable next monday if today is friday
      if (weekdayNum === 5) {
        const nextMonday = moment().add(3, 'days')
        return current.isSame(nextMonday, 'day')
      }
      // disable next monday if today is weekend
      if (weekdayNum === 6 || weekdayNum === 7) {
        const nextMonday = num => current.isSame(moment().add(num, 'days'), 'day')
        return weekdayNum === 6 ? nextMonday(2) : nextMonday(1)
      }

      // disable tomorrow if the current time more than midday time
      if(moment().startOf('day').fromNow().slice(0, 2) >= 12){
        const tomorrow = moment().add(1,'days');
        return current.isSame(tomorrow, 'day')
      } 
    },
  }
}
</script>

<style scoped lang="scss">
  .wrapper {
    display: flex;
    min-height: 42px;
    align-items: center;
    margin-bottom: 25px;
  }
  .hasError {
    border-radius: 5px;
    border: 1px solid #f5222d;
  }
  .label {
    margin: 0 15px 0 0;
    font-weight: 600;
    &--hasError {
      color: #f5222d;
    }
    &::first-letter {
      text-transform: uppercase;
    }
  }
</style>
