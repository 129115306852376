<template>
  <a-card
    style="background: transparent"
    :hoverable="true"
  >
    <slot name="title" :title="fileInfo.title"></slot>
    <slot name="description" :description="fileInfo.description"></slot>
    <a-upload-dragger
      :name="fileInfo.name"
      :multiple="false"
      :customRequest="getFile"
      @change="handleChangeFile($event, fileName)"
      :remove="handleRemove"
      :disabled="disabled"
      :fileList="fileList"
    >
      <p class="ant-upload-drag-icon">
        <a-icon type="inbox" />
      </p>
      <p class="ant-upload-text">
        {{ fileInfo.uploadText }}
      </p>
    </a-upload-dragger>
    <a-badge
      v-show="(fileInfo.isDirty && !fileInfo.loading) || fileInfo.file.size"
      :status="fileInfo.errorText.length ? 'error' : 'success'"
      :text="fileInfo.errorText.length ? 'Fout' : 'Goedgekeurd'"
    />
    <a-spin
      v-if="fileInfo.loading"
      style="margin-left: 5px"
    >
      <a-icon
        slot="indicator"
        type="loading"
        style="font-size: 24px"
        :spin="true"
      />
    </a-spin>
    <a-list
      v-show="fileInfo.errorText.length"
      size="small"
      :bordered="true"
      style="margin-top: 15px; overflow: hidden; text-overflow: ellipsis"
    >
      <a-list-item
        v-for="(error, idx) of fileInfo.errorText"
        :key="idx + error"
      >
        <a-badge
          :status="'error'"
          :text="error"
        />
      </a-list-item>
    </a-list>
  </a-card>
</template>

<script>

export default {
  name: "CustomFileInput",
  props: {
    fileInfo: Object,
    fileName: String,
    disabled: Boolean
  },
  created () {
    if (this.fileInfo.file.name) {
      this.fileList.push({
        ...this.fileInfo.file
      })
    }
  },
  beforeDestroy() {
    this.$emit('do-empty', { title: this.fileName })
  },
  data() {
    return {
      onSuccess: false,
      fileList: []
    }
  },
  methods: {
    getFile ({ onSuccess }) {
      setTimeout(() => {
        this.onSuccess = true;
        onSuccess("ok");
      }, 0)
    },
    handleRemove (file) {
      this.$store.commit('warehouse/DELETE_FILE_FROM_STORE',{ title: this.fileName })
    },
    handleChangeFile (info, title) {
      this.fileList = info.fileList;
      if (info.fileList.length >= 2) {
        this.fileList = info.fileList.filter((file, idx, arr) => {
          return idx === arr.length - 1
        })
      }
      if (this.onSuccess && info.fileList.length) {
        this.$emit('check', { info, title })
        this.$store.commit('warehouse/MAKE_FILE_IS_DIRTY',{ title })
        this.onSuccess = false
      }
      if (!info.fileList.length) {
        this.$emit('do-empty', { title })
      }
    },
  }
}
</script>

<style scoped>

</style>
