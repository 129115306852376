<template>
  <Spinner v-if="isShippingLoading" />
  <div v-else>
    <div class="progress-bar">
      <h2 class="progress-bar__title">
        <span v-if="currentStep === 1">
          Levering
        </span>
        <span v-if="currentStep === 2">
          Overzicht
        </span>
      </h2>
      <p class="progress-bar__numbers">
        <b>
          <span>{{ currentStep }} </span>/ 2
        </b>
      </p>
    </div>
    <a-progress
      type="line"
      size="small"
      strokeColor="#FFA300"
      :percent="(currentStep / 2) * 100"
      :show-info="false"
    />
    <p class="progress-bar__subtitle">
      <span v-show="currentStep === 1">
        Voer hier de gegevens in voor de levering van je goederen
      </span>
      <span v-show="currentStep === 2">
        Controleer en bevestig je ingevoerde gegevens, dit is hierna niet meer aan te passen
      </span>
    </p>
    <transition name="component-fade" mode="out-in">
      <div v-if="currentStep === 1">
        <CustomInput
          v-for="(value, name) of formFields"
          :fieldData="value"
          :fieldName="name"
          :formFields="formFields"
          :validation="$v.formFields[name]"
          :key="name + value.id"
          :autoCompleteList="addressList"
          @selectHandler="selectHandler"
          @deleteListItem="deleteAddressFromAutocomplete"
          @editTemplateHandler="editTemplateHandler"
          isNeedEditTemplate
        />
        <CustomDateInput
          :label="dateField.label"
          :value="dateField.name || null"
          :validation="$v.dateField"
          @change="customDateInputChange"
        />
        <div
          v-if="formFields.lvb.name" 
          style="display: flex; justify-content: space-between; margin-bottom: 25px"
        >
          <div v-for="(file, key) of files" :key="key" style="width: calc(50% - 20px)">
            <CustomFileInput
              @check="checkFile($event)"
              @do-empty="doEmpty($event)"
              :file-info="file"
              :file-name="key"
              :key="key"
              :disabled="isFilesDisabled"
            >
              <template v-slot:title="slotProps">
                <h2>{{ slotProps.title }}</h2>
              </template>
              <template v-slot:description="slotProps">
                <p style="max-width: 500px">{{ slotProps.description }}</p>
              </template>
            </CustomFileInput>
          </div>
        </div>

        <div 
          v-else
          style="margin-bottom: 25px"
        >
          <CustomFilesUploader
            :file="multiUploadFiles"
            :addErrorsFunc="ADD_MULTI_UPLOAD_ERROR_TEXT"
            :clearErrorsFunc="CLEAR_MULTI_UPLOAD_FILES_ERROS"
            :addFileFunc="addFileToMultiUploadDocument"
            :removeFileFunc="removeFileToMultiUploadDocument"
            :clearFileState="CLEAR_MULTI_LOAD_FILE_STATE"
            multiple
          >
            <template v-slot:title="slotProps">
              <a-tooltip
                class="upload__wrapper"
                placement="right"
              >
                <h2 class="upload__title">{{slotProps.title}}</h2>
                <template slot="title">
                  <h3
                    class="informationText"
                    style="color: #fff; text-align: center;"
                  >
                    {{slotProps.toolTipText}}
                  </h3>
                </template>
                <a-icon
                  type="info-circle"
                  style="color: black"
                />
              </a-tooltip>
            </template>
          </CustomFilesUploader>
        </div>
        <CustomCheckbox
          v-if="!formFields.lvb.name"
          className="form__checkbox"
          :title="saveDeliveryLayout.label"
          @checkHandler="checkboxHandler"
          :checked="saveDeliveryLayout.checked"
          :disabled="isCheckboxDisabled"
        />
      </div>
      <WarehouseFormCheckout v-else :validations="$v.checkoutFormFields" />
    </transition>
    <a-button-group size="large" class="step-buttons">
      <a-button
        type="primary"
        @click="backButtonHandler"
      >
        <a-icon type="left" />
        Vorige stap
      </a-button>
      <a-button
        type="primary"
        :disabled="isNextStepButtonDisabled"
        :loading="isNextStepButtonDisabled"
        @click="nextButtonHandler"
      >
        Bevestigen
        <a-icon type="right" />
      </a-button>
    </a-button-group>
    <CustomModalWindow
      :visible="isOpenModal"
      okText="Verandering"
      centered
      title="Leveranciersgegevens bewerken"
      @cancel="closeModal"
      @ok="saveEditedTemplate"
      :loading="isChangingTemplateData"
      :closable="!isChangingTemplateData"
      :disabledCloseBtn="isChangingTemplateData"
      :disabledOkBtn="$v.templateData.$invalid"
    >
      <CustomInput
        v-for="(value, name) of templateData"
        :fieldData="value"
        :fieldName="name"
        :formFields="templateData"
        :validation="$v.templateData[name]"
        :key="name + value.id"
      />
    </CustomModalWindow>
    <CustomSpinner 
      v-if="isLoadingTemplate" 
      size="150" 
      centered
    />
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from "vuex";
import { maxLength, required, requiredIf } from "vuelidate/lib/validators";
import { closeNotification, makeNotification } from "@/utils/notificationHandler";
import { FILES_NOTIFICATION_KEY, DELETE_ADDRESS_TEMPLATE_KEY } from "./notificationConstants";
import CustomFileInput from "./CustomFileInput";
import CustomInput from "./CustomInput";
import CustomFilesUploader from "../customComponents/CustomFilesUploader.vue";
import WarehouseFormCheckout from "./WarehouseFormCheckout";
import Spinner from "./Spinner";
import CustomDateInput from "./CustomDateInput";
import CustomCheckbox from "./CustomCheckbox.vue";
import editTemplates from "../../mixins/editTemplates";
import clearTemplateFields from "../../mixins/clearTemplateFields";

export default {
  name: "WarehouseForm",
  components: {
    CustomDateInput,
    CustomInput,
    CustomFileInput,
    CustomCheckbox,
    WarehouseFormCheckout,
    Spinner,
    CustomFilesUploader,
  },
  mixins: [clearTemplateFields, editTemplates],
  beforeDestroy () {
    closeNotification({ key: FILES_NOTIFICATION_KEY })
    this.$store.commit('warehouse/CLEAR_SAVE_DELIVERY_TEMPLATE_ERRORS')
  },
  data() {
    return {
      uploadOptions: {
        link: '/api/v1/booking/document/upload',
        needToken: true,
        method: 'POST'
      },
      templateFields: [
        "addition",
        "city", 
        "houseNumber", 
        "street", 
        "zipCode", 
        "country"
      ],
      outerField: 'formFields',
      resetValidateField: 'street',
    }
  },
  watch: {
    currentStep (newVal, oldVal) {
      if (oldVal !== newVal && oldVal === 1) {
        closeNotification({ key: FILES_NOTIFICATION_KEY })
        this.$store.commit('warehouse/CLEAR_SAVE_DELIVERY_TEMPLATE_ERRORS')
      }
    },
    isCheckboxDisabled (checked) {
      checked && this.$store.commit('warehouse/SAVE_DELIVERY_DATA_HANDLER', { bool: false })
    }
  },
  computed: {
    ...mapGetters('warehouse', [
      'formFields', 
      'files', 
      'currentStep', 
      'isShippingLoading', 
      'dateField',
      'checkoutFormFields',
      'saveDeliveryLayout',
      'addressList',
      'templateData',
      'isChangingTemplateData',
      'isLoadingTemplate',
      'mainTemplateField',
      'multiUploadFiles',
    ]),
    isFilesDisabled () {
      return !!Object.keys(this.files).find(key => this.files[key].loading)
    },
    shouldShowFileInputs () {
      return !!this.formFields.lvb.name
    },
    isNextStepButtonDisabled () {
      return this.isFilesLoading || this.isShippingLoading
    },
    isFilesLoading () {
      return Object.keys(this.files).some(key => this.files[key].loading)
    },
    isFilesUploaded () {
      return Object.keys(this.files).every(key => this.files[key].file.size)
    },
    isCheckboxDisabled () {
      const inputStreetName = this.formFields.street.name;
      return inputStreetName && this.addressList.some(({ label }) => label.toLowerCase() === inputStreetName.toLowerCase());
    },
  },
  created () {
    this.lvbLocations();
    this.getDeliveryAddressList();
  },
  methods: {
    ...mapActions('warehouse', 
    [
      'lvbLocations', 
      'checkDocument', 
      'shipProducts', 
      'getDeliveryAddressList',
      'setDeliveryAddressFromList',
      'deleteAddressItem',
      'editDeliveryTemplateItem',
      'addFileToMultiUploadDocument',
      'removeFileToMultiUploadDocument',
    ]),
    ...mapMutations('warehouse', 
    [
      'CLEAR_MULTI_UPLOAD_FILES_ERROS',
      'ADD_MULTI_UPLOAD_ERROR_TEXT',
      'CLEAR_MULTI_LOAD_FILE_STATE',
    ]),
    clearAutofilledData ({ step, fields }) {
      this.$store.commit('warehouse/CLEAR_AUTOFILLED_DATA', { step, fields })
    },
    checkFile ({ info, title }) {
      this.checkDocument({ info, title })
    },
    doEmpty ({ title }) {
      this.$store.commit('warehouse/MAKE_FILE_EMPTY', { title })
    },
    backButtonHandler () {
      if (this.currentStep === 1) {
        this.$store.commit('warehouse/END_FILLING_FORM')
      } else {
        this.$store.commit('warehouse/STEP_BACK')
      }
    },
    nextButtonHandler () {
      if (this.currentStep === 1) {
        this.$v.formFields.$touch()
        this.$v.dateField.$touch()
        if (this.$v.formFields.$invalid || this.$v.dateField.$invalid) return
        if (this.formFields.lvb.name) {
          if (!this.isFilesLoading && this.isFilesUploaded) {
            this.$store.commit('warehouse/STEP_FRONT')
          } else {
            makeNotification({
              type: 'error',
              key: FILES_NOTIFICATION_KEY,
              description: 'Gelieve allebei de bestanden te uploaden',
              message: 'Onjuist',
              duration: 5
            })
          }
        } else {
          this.$store.commit('warehouse/STEP_FRONT')
          this.saveDeliveryLayout.checked && this.$store.dispatch('warehouse/saveDeliveryData')
        }
      }
      else if (this.currentStep === 2) {
        this.$v.checkoutFormFields.$touch()
        if (this.$v.checkoutFormFields.$invalid) return;
        else this.shipProducts()
      }
    },
    customDateInputChange (date) {
      this.$store.commit('warehouse/SAVE_DATE_INPUT_VALUE', {
        value: date !== null ? date.format('YYYY-MM-DD') : null
      })
    },
    checkboxHandler(bool) {
      this.$store.commit('warehouse/SAVE_DELIVERY_DATA_HANDLER', { bool })
    },
    selectHandler({ key }) {
      this.getTemplatesItem(key, true); 
    },
    async deleteAddressFromAutocomplete({ id }) {
      await this.deleteAddressItem(id);
      
      this.getDeliveryAddressList();
    },
    getTemplatesItem (id, bool) {
      return this.setDeliveryAddressFromList({id, isToAutoFillData: bool});
    },
    editTemplateItem (id) {
      return this.editDeliveryTemplateItem(id)
        .then(() => this.getDeliveryAddressList());
    },
  },
  validations () {
    return {
      formFields: {
        lvb: {
          name: {
            required
          }
        },
        lvbOptions: {
          name: {
            required: requiredIf(function () {
              return !!this.formFields.lvb.name
            }),
            maxLength: maxLength(170),
          }
        },
        country: {
          name: {
            required
          }
        },
        street: {
          name: {
            required: requiredIf(function () {
              return !this.formFields.lvb.name
            }),
            maxLength: maxLength(30),
          }
        },
        zipCode: {
          name: {
            required: requiredIf(function () {
              return !this.formFields.lvb.name
            }),
            maxLength: maxLength(20),
          }
        },
        houseNumber: {
          name: {
            required: requiredIf(function () {
              return !this.formFields.lvb.name
            }),
            maxLength: maxLength(30),
          }
        },
        city: {
          name: {
            required: requiredIf(function () {
              return !this.formFields.lvb.name
            }),
            maxLength: maxLength(30),
          }
        },
        addition: {
          name: {
            maxLength: maxLength(30),
          }
        },
      },
      dateField: {
        name: {
          required
        }
      },
      checkoutFormFields: {
        personalRef: {
          name: {
            maxLength: maxLength(25),
          }
        }
      },
      templateData: {
        country: {
          name: {
            required: requiredIf(() => this.isOpenModal),
          }
        },
        street: {
          name: {
            required: requiredIf(() => this.isOpenModal),
            maxLength: maxLength(30),
          }
        },
        zipCode: {
          name: {
            required: requiredIf(() => this.isOpenModal),
            maxLength: maxLength(20),
          }
        },
        houseNumber: {
          name: {
            required: requiredIf(() => this.isOpenModal),
            maxLength: maxLength(30),
          }
        },
        city: {
          name: {
            required: requiredIf(() => this.isOpenModal),
            maxLength: maxLength(30),
          }
        },
        addition: {
          name: {
            maxLength: maxLength(30),
          }
        },
      }
    }
  },
}
</script>

<style scoped lang="scss">
.progress-bar {
  display: flex;
  justify-content: space-between;
  &__title {
    color: #FFA300;
    margin-bottom: 0;
    display: flex;
    align-items: flex-end;
  }
  &__subtitle {
    margin-top: 8px;
    margin-bottom: 25px;
    font-size: 16px;
    font-weight: 500;
    max-width: 550px;
  }
  &__numbers {
    margin-bottom: 0;
    display: flex;
    align-items: flex-end;
    font-size: 16px;
    span {
      color: #FFA300;
    }
  }
}
.step-buttons {
  display: flex;
  justify-content: space-between;
  margin: 40px 0 0 0;
}
.upload{
  &__wrapper {
    display: inline-flex;
    align-items: center;
    cursor: pointer;
    margin-bottom: 10px;
  }
  &__title {
    margin: 0;
    margin-right: 5px;
  }
} 
::v-deep .anticon-info-circle {
  margin-top: 0;
}
</style>
