<template>
  <div style="height: 100%">
    <CustomSpinner
      v-if="isProductsLoading"
      size="100"
      :centered="!isAnyProductExists"
      :bottomed="isAnyProductExists"
      key="1"
    />
    <transition name="component-fade" mode="out-in">
      <div v-if="!isFormFilling && !isSuccessfullyPassed" key="2">
        <h1 class="page-title">Magazijn</h1>
        <a class="itemLink download-file" :href="warehouseManualFile" style="display: inline-block">
          Handleiding Warehouse
        </a>
        <template v-if="!products.length && !isProductsLoading">
          <a-empty />
        </template>
        <template v-if="products.length">
          <div class="createShipmentBtn">
            <a-button
              type="primary"
              size="large"
              :disabled="
                isCreateShipmentBtnDisabled ||
                hasPersonalRefValidationError ||
                isSomePersonalRefsLoading ||
                isPersonalRefUpdating
              "
              @click="createShipmentButtonHandler"
            >
              Verstuur producten
            </a-button>
          </div>
          <div class="product-list">
            <div class="product-list__header">
              <p
                v-for="col of cols"
                class="product-list__header-item"
                :style="{ width: widthOfColumns }"
                :key="col.view"
              >
                {{ col.view }}
              </p>
            </div>
            <div
              class="product-list__item"
              v-for="(product, idx) of products"
              :key="product.id"
            >
              <div
                class="product-list__item-row"
               :class="{'product-list__item-row--cursor': product.product_subs.length }"
                @click="product.showSubProducts = !product.showSubProducts"
              >
                <a-icon
                  v-if="product.product_subs.length" 
                  class="product-list__open-icon"
                  style="fontSize: 12px"
                  :type="typeOpenBtn(product.showSubProducts)" 
                />
                <div
                  class="product-list__item-col"
                  :style="{ width: widthOfColumns }"
                >
                  <a-form-item
                    :validate-status="validateInput(product) ? 'error' : 'success'"
                    style="margin-bottom: 0; display: flex"
                  >
                    <a-input-number
                      size="large"
                      :value="product.countOfBoxes"
                      @change="countOfBoxesHandler(idx, $event)"
                      :disabled="
                        !product.available || product.maxBoxesCount === 0 || hasSubProducts(idx) || !product.status
                      "
                      :min="1"
                      :max="product.maxBoxesCount"
                    />
                  </a-form-item>
                </div>
                
                <div
                  class="product-list__item-col"
                  :style="{ width: widthOfColumns }"
                >
                  <span>{{ product.booking_uuid }}</span>
                </div>
                
                <div
                  v-for="(option, key) of product.options"
                  :key="option.value + key"
                  class="product-list__item-col"
                  :style="{ width: widthOfColumns }"
                >
                  <span>{{ option.value }}</span>
                </div>

                <div
                  class="product-list__item-col"
                  :style="{ width: widthOfColumns }"
                >
                  <span>{{
                    product.status ? "Beschikbaar" : "Niet beschikbaar"
                  }}</span>
                </div>

                <div
                  class="product-list__item-col product-list__item-col--btn"
                  :style="{ width: widthOfColumns }"
                >
                  <a-form-item
                    :validate-status="
                      $v.products.$each[idx].personalReference.$invalid
                        ? 'error'
                        : 'success'
                    "
                    style="margin-bottom: 0; display: flex"
                  >
                    <a-input-search
                      :loading="
                        $store.state.warehouse.products[idx]
                          .isPersonalRefLoading
                      "
                      :disabled="
                        $store.state.warehouse.products[idx]
                          .isPersonalRefLoading
                      "
                      size="large"
                      style="
                        width: auto;
                        background: transparent;
                        margin-right: 15px;
                      "
                      v-model="
                        $store.state.warehouse.products[idx].personalReference
                      "
                      @blur="personalReferenceBlurHandler(idx, product.id)"
                      @change="personalReferenceDisableHandler"
                      @click.stop
                    />
                  </a-form-item>
                </div>
              </div>

              <div v-if="product.showSubProducts && product.product_subs.length">
                <span class="subProd__title">
                  Product variant:
                </span>
                <div class="product-list__header product-list__header--positioned">
                  <p
                    v-for="subProdCol of subProdCols"
                    :style="{ width: widthOfSubProdColumns }"
                    class="product-list__header-item subProd__row-title"                
                    :key="subProdCol.view"
                  >
                    {{ subProdCol.view }}
                  </p>
                </div>
                <div
                  class="product-list__item"
                  style="width: 55%; margin-right: auto"
                  v-for="(subProduct, subProdIdx) of product.product_subs"
                  :key="subProduct.id"
                >
                  <div class="product-list__item-row product-list__item-row--positioned">
                    <div
                      class="product-list__item-col"
                      :style="{ width: widthOfSubProdColumns }"
                    >
                      {{ subProduct.description }}
                    </div>
                    
                    <div
                      class="product-list__item-col"
                      :style="{ width: widthOfSubProdColumns }"
                    >
                      <a-form-item
                        :validate-status="validateSubProdInput(subProduct) ? 'error' : 'success'"
                        style="margin-bottom: 0; display: flex"
                      >
                        <a-input-number
                          size="large"
                          :value="subProduct.countOfSubProductBoxes"
                          @change="countOfSubProductBoxesHandler({ idx, subProdIdx, $event })"
                          :min="1"
                          :max="subProduct.warehouseCountOfBoxes"
                          :disabled="!product.status || !subProduct.warehouseCountOfBoxes"
                        />
                      </a-form-item>
                    </div>

                    <div
                      class="product-list__item-col"
                      :style="{ width: widthOfSubProdColumns }"
                    >
                      {{ subProduct.boxesPerPallet }}
                    </div>
                    <div
                      class="product-list__item-col"
                      :style="{ width: widthOfSubProdColumns }"
                    >
                      {{ subProduct.warehouseCountOfBoxes }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <CustomPagination
              v-if="isAnyProductExists"
              showSizeChanger
              :totalItems="totalItems"
              :itemPerPage="itemPerPage"
              :currentPage="currentPage"
              @changePage="paginationHandler"
              @itemPerPageHandler="itemPerPageHandler"
            />
          </div>
        </template>
      </div>
      <div v-else-if="isFormFilling" key="4" style="height: 100%">
        <WarehouseForm />
      </div>
      <div v-else-if="!isFormFilling && isSuccessfullyPassed" key="3">
        <Success @next="afterSuccessHandler" />
      </div>
    </transition>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from "vuex";
import { PERSONAL_REF_ERROR_KEY } from "./notificationConstants";
import { closeNotification } from "@/utils/notificationHandler";
import { maxLength } from "vuelidate/lib/validators";
import WarehouseForm from "./WarehouseForm";
import Spinner from "./Spinner";
import Success from "./Success";
import CustomPagination from "@/components/customComponents/CustomPagination";
import CustomSpinner from "@/components/customComponents/CustomSpinner";
import { InputNumber } from "ant-design-vue";

export default {
  name: "WarehouseStore",
  data() {
    return {
      cols: [
        {
          view: "Aantal te vervoeren dozen",
        },
        {
          view: "Boekingsreferentie",
        },
        {
          view: "Beschrijving van het product",
        },
        {
          view: "Aantal dozen per pallet",
        },
        {
          view: "Pallets ",
        },
        {
          view: "Aantal dozen in warehouse",
        },
        {
          view: "Status",
        },
        {
          view: "Referentie",
        },
      ],
      subProdCols: [
        {
          view: "Product specificaties",
        },
        {
          view: "Aantal stuks naar warenhuis",
        },
        {
          view: "Boxes per pallet",
        },
        {
          view: "Antal stuks product type",
        }
      ],
      defaultLoadingPageParams: {
        currentPage: 1, 
        itemsPerPage: 10
      },
      isPersonalRefUpdating: false,
    };
  },
  components: {
    WarehouseForm,
    Success,
    Spinner,
    CustomPagination,
    CustomSpinner,
    AInputNumber: InputNumber,
  },
  created() {
      this.fetchWarehouseProducts(this.defaultLoadingPageParams);
  },
  beforeDestroy() {
    closeNotification({ key: PERSONAL_REF_ERROR_KEY });
    this.$store.commit("warehouse/RESET_COUNT_OF_BOXES");
    this.UPDATE_PAGINATION();
    this.SET_PRODUCTS({ products: [] });
  },
  watch: {
    isFormFilling(val) {
      if (val) closeNotification({ key: PERSONAL_REF_ERROR_KEY });
    },
  },
  computed: {
    ...mapGetters("warehouse", [
      "products",
      "isFormFilling",
      "isProductsLoading",
      "isSuccessfullyPassed",
      "isCreateShipmentBtnDisabled",
      "isSomePersonalRefsLoading",
      "itemPerPage",
      "totalItems",
      "currentPage"
    ]),
    widthOfColumns() {
      return 100 / this.cols.length + "%";
    },
    widthOfSubProdColumns() {
      return 100 / this.subProdCols.length + "%";
    },
    hasPersonalRefValidationError() {
      return this.$v.products.$invalid;
    },
    warehouseManualFile () {
      return `${process.env.VUE_APP_API_URL}/dashboard/manual`;
    },
    isAnyProductExists () {
      return !!(this.products.length);
    },
  },
  methods: {
    ...mapActions("warehouse", ["fetchWarehouseProducts", "updatePersonalRef"]),
    ...mapMutations('warehouse', ['CLEAR_PAGINATION_LIST', "UPDATE_PAGINATION", "SET_PRODUCTS"]),
    countOfBoxesHandler(index, num) {
      this.$store.commit("warehouse/COUNT_OF_BOXES_HANDLER", { index, num });
    },
    createShipmentButtonHandler() {
      if (
        !this.isCreateShipmentBtnDisabled &&
        !this.hasPersonalRefValidationError &&
        !this.isSomePersonalRefsLoading
      ) {
        this.$store.commit("warehouse/START_FILLING_FORM");
      }
    },
    afterSuccessHandler() {
      this.$store.commit("warehouse/RESTART_WAREHOUSE");
      this.fetchWarehouseProducts(this.defaultLoadingPageParams);
    },
    personalReferenceDisableHandler() {
      this.isPersonalRefUpdating = true;
    },
    personalReferenceBlurHandler(idx, productId) {
      const personalRefValidation =
        this.$v.products.$each[idx].personalReference;
      const {
        personalReferenceFromBackend,
        personalReference,
        personalRefLoading,
      } = this.$store.state.warehouse.products[idx];

      personalRefValidation.$touch();
      if (
        personalRefValidation.$invalid ||
        personalRefLoading ||
        personalReference.trim() === personalReferenceFromBackend.trim()
      ) {
        this.isPersonalRefUpdating = false;
        return this.$store.commit(
          "warehouse/CHANGE_PERSONAL_REF_TO_BACK_VARIANT",
          { productId }
        );
      }

      this.updatePersonalRef({ productId, personalReference })
        .then(() => this.isPersonalRefUpdating = false);
    },
    validateInput(product) {
      return (product.countOfBoxes > product.maxBoxesCount || product.countOfBoxes < 1) && typeof product.countOfBoxes !== 'string';
    },
    validateSubProdInput(subProduct) {
      return (+subProduct.countOfSubProductBoxes > +subProduct.warehouseCountOfBoxes || +subProduct.countOfSubProductBoxes < 1) && 
      typeof subProduct.countOfSubProductBoxes !== 'string'
    },
    hasSubProducts(idx) {
      return !!this.products[idx].product_subs.length;
    },
    countOfSubProductBoxesHandler({ idx: productIdx, subProdIdx, $event: inputValue }) {
      this.$store.commit("warehouse/COUNT_OF_SUBPRODUCTS_BOXES_HANDLER", { productIdx, subProdIdx, inputValue });
    },
    typeOpenBtn (value) {
      return value ? "up" : "down";
    },
    fetchItems ({ currentPage, itemsPerPage }) {
      this.fetchWarehouseProducts({ currentPage, itemsPerPage })
        .then(() => window.scrollTo({ top: 0, behavior: 'smooth'}))
    },
    paginationHandler ({ currentPage, itemsPerPage }) {
      this.fetchItems({ currentPage, itemsPerPage });
    },
    itemPerPageHandler ({ currentPage, itemsPerPage }) {
      this.fetchItems({ currentPage, itemsPerPage });
    }
  },
  validations() {
    return {
      products: {
        $each: {
          personalReference: {
            maxLength: maxLength(25),
          },
          product_subs: {
            $each: {
              countOfSubProductBoxes: {
                countOfBoxesMoreThanWarehouseLimit: (val, vm) => {  
                  return !(val > vm.warehouseCountOfBoxes);
                }
              }
            }
          }
        },
      },
    };
  },
};
</script>

<style scoped lang="scss">
.createShipmentBtn {
  display: flex;
  padding: 20px 10px;
  justify-content: center;
}
.product-list {
  &__item {
    border: 1px solid #e0e0e0;
    padding: 15px 12px;
    border-radius: 4px;
    margin-top: 15px;
    &:hover {
      background: rgba(255, 140, 90, 0.09);
      transition: 0.3s;
    }
    &:hover .product-list__open-icon {
      color: #FFA300;
    }
  }
  &__item-row {
    display: flex;
    align-items: center;
    &--cursor{
      cursor: pointer;
    }
  }
  &__item-col {
    display: flex;
    align-items: center;
    justify-content: center;
    &--btn {
      display: flex;
      align-items: center;
    }
  }
  &__header {
    display: flex;
    padding: 20px 12px 0;
    &--positioned {
      width: 55%;
      margin-right: auto;
    }
  }
  &__header-item {
    text-align: center;
    padding-right: 25px;
    font-weight: 600;
    margin-bottom: 0;
    &::first-letter {
      text-transform: uppercase;
    }
  }
  &__open-icon {
    position: absolute;
  }
}
.subProd{
  &__title{
    margin-top: 10px;
    display: table;
    font-weight: 600;
    border-bottom: 1px solid #e0e0e0,
  }
  &__row-title {
    padding-right: 0;
  }

}
.page-title{
  margin: 0;
}
.download-file{
  margin: 0 0 14px 0;
}
</style>
